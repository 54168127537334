import { useEffect, useState } from 'react'
import { useAnimations, useGLTF } from '@react-three/drei'
import { useControls } from 'leva'
import randomColor from 'randomcolor';
import Model from './Model';

const modelsOrigin = process.env.REACT_APP_SOURCE_ORIGIN ?? "https://locomotive.ca/";

// Get the models
let models = {}
await fetch(modelsOrigin + "/en/agency").then(data => data.text()).then(data => {
	const parser = new DOMParser();
	const dom = parser.parseFromString(data, "text/html")

	let unorderedModels = {};
	dom.querySelectorAll('[data-team-list-infos]').forEach(item => {
		const modelData = JSON.parse(item.dataset.teamListInfos)
		unorderedModels[modelData.name] = modelsOrigin + '/' + modelData.model
	});

	// Sort alphabetically
	models = Object.keys(unorderedModels).sort().reduce(
		(obj, key) => {
			obj[key] = unorderedModels[key];
			return obj;
		},
		{}
	);
})

export default (props) => {
    const color = randomColor();

    const randomModel = models[Object.keys(models)[Math.floor(Math.random()*Object.keys(models).length)]]

    const [modelConfig, setModelConfig] = useControls(props.label.toString(), () => ({
        model: {
			value: randomModel,
			options: models
		},
        position: {
            value: { x: 0, z: 0 },
            step: 0.1
        },
        rotation: {
            value: 0,
            step: 0.1
        },
        scale: {
            value: 1,
            min: 0,
            max: 4
        }
    }), { color }, [])

    return (
        <Model label={props.label} position={modelConfig.position} rotation={modelConfig.rotation} scale={modelConfig.scale} src={modelConfig.model} />
    );
  };