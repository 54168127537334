import { useEffect, useState } from 'react'
import { useAnimations, useGLTF } from '@react-three/drei'
import { useControls, button } from 'leva';

export default (props) => {
    const { nodes, scene, animations } = useGLTF(props.src + '?id=' + props.label)

    // Extract animation actions
    const { ref, mixer, actions, names } = useAnimations(animations, scene)

    const [animationConfig, setAnimationConfig] = useControls(props.label.toString(), () => ({
        animation: {
            value: names[0],
            options: names
        }
    }), [names])

    useEffect(() => {
        setAnimationConfig({ animation: names[0] })
        actions[animationConfig.animation]?.reset().fadeIn(0.25).play()
    }, [props.src])

    // Change animation when the index changes
    useEffect(() => {
        actions[animationConfig.animation]?.reset().fadeIn(0.25).play()
        // In the clean-up phase, fade it out
        return () => actions[animationConfig.animation].fadeOut(0.21)
    }, [animationConfig.animation])

    return (
        <primitive position={[props.position.x,0, props.position.z]} rotation={[0,props.rotation,0]} scale={[props.scale,props.scale,props.scale]} object={scene} />
    );
  };